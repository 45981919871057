import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPageSetDataById } from "@services";
import Breadcrumb from "@common/Breadcrumb";
import {
  formatBytes,
  handleDownload,
  handleAdd,
  getImageSrc,
  determineImagePath,
} from "@helpers/utilities";
import { RootState } from "store";
import { Model } from "types";

const PagesetCentralDetails = () => {
  const { id, software } = useParams();
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );
  const { languageCode } = useSelector(
    (state: RootState) => state.languageData,
  );
  const isAuthenticated = useSelector(
    (state: RootState) => state.user.isAuthenticated,
  );

  const [model, setModel] = useState<Model>();
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const fetchDataById = async () => {
      const data = await getPageSetDataById(languageCode, id, software);
      setModel(data);
      const imagePath = determineImagePath(data.imageUrl, data.software);
      setImageSrc(imagePath);
    };
    fetchDataById();
  }, [id, languageCode, software]);

  useEffect(() => {
    const imagePath =
      (model?.imageUrl && `/azure/pagesetthumbnails/${model.imageUrl}?w=237`) ||
      "/assets/images/pagesets/all-backup.png";
    setImageSrc(imagePath);
  }, [model]);

  const imageSource = getImageSrc(model?.software, imageSrc);

  return (
    <>
      {translations && (
        <>
          <div className="container-full">
            <div className="container myStuff-header">
              <h1>{translations.Pageset_Details}</h1>
              <Breadcrumb
                items={[
                  {
                    href: "/pageset-central",
                    label: translations.Pageset_Central,
                  },
                  { label: translations.Pageset_Details },
                ]}
              />
            </div>
          </div>

          <div className="container psc-details">
            {model && (
              <div className="row psc-details-info">
                <div className="col-md-5 col-md-push-7 details">
                  <h2>{model.title}</h2>

                  <div className="table-responsive">
                    <table className="table table-condensed">
                      <tbody>
                        <tr>
                          <td>{translations.Creator}</td>
                          <td>{model.author}</td>
                        </tr>
                        <tr>
                          <td>{translations.Date}</td>
                          <td>
                            {new Date(model.dateCreatedUtc).toLocaleString(
                              languageCode,
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>{translations.Size}</td>
                          <td>{formatBytes(model.size)}</td>
                        </tr>
                        <tr>
                          <td>{translations.MYTD_panelTitleDownloads}</td>
                          <td>{model.downloads}</td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <hr />
                          </td>
                        </tr>
                        <tr>
                          <td>{translations.Software}</td>
                          <td>
                            {model.compatibleWith
                              ? model.compatibleWith.join(", ")
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>{translations.Language}</td>
                          <td>{model.language}</td>
                        </tr>

                        {model.type !== "" && (
                          <tr>
                            <td>{translations.Type}</td>
                            <td>{model.type}</td>
                          </tr>
                        )}

                        <tr>
                          <td>{translations.Categories}</td>
                          <td>
                            {model.categories
                              ? model.categories.join(", ")
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="hidden-md hidden-lg">
                    <p className="description">{model.description}</p>
                    <div className="well">
                      <img src={imageSource} alt="" />
                    </div>
                  </div>

                  <button
                    id="btnAdd"
                    className="btn btn-primary btn-lg"
                    style={{ marginRight: "10px" }}
                    onClick={() => handleAdd(isAuthenticated)}
                  >
                    {translations.MYTD_addToMyStuffBtnTxt}
                  </button>
                  <button
                    id="btnDL"
                    className="btn btn-primary btn-lg"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDownload(model.pagesetId, model.software);
                    }}
                  >
                    {translations.Download}
                  </button>
                </div>

                <div className="col-md-6 col-md-pull-5 hidden-xs hidden-sm">
                  <div className="well text-center">
                    <img src={imageSource} alt="" />
                  </div>
                  <p className="description">{model.description}</p>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PagesetCentralDetails;
