import { configureStore, combineReducers, Action } from "@reduxjs/toolkit";
import thunk, { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import {
  translationsReducer,
  userDetailsReducer,
  languageDataReducer,
  commonDataReducer,
} from "./reducers";

const rootReducer = combineReducers({
  translations: translationsReducer,
  languageData: languageDataReducer,
  user: userDetailsReducer,
  common: commonDataReducer,
});

export const getConfiguredStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: [thunk],
  });

export const store = getConfiguredStore();
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch &
  ThunkDispatch<RootState, undefined, Action<string>>;
export const useAppDispatch: () => AppDispatch = useDispatch;
