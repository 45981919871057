import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Modal } from "react-bootstrap";

interface ModalTermsAndConditionsProps {
  show: boolean;
  onClose: () => void;
  termsContent: string;
}

const ModalTermsAndConditions: React.FC<ModalTermsAndConditionsProps> = ({
  show,
  onClose,
  termsContent,
}) => {
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );
  return (
    <Modal show={show} onHide={onClose} className={show ? "in" : ""} centered>
      <Modal.Header>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h2 className="modal-title">{translations.Terms_and_Conditions}</h2>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "500px", overflow: "auto" }}>
        <iframe
          src={termsContent}
          title="Terms and Conditions"
          style={{ width: "100%", height: "400px", border: "none" }}
        ></iframe>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={onClose}
          type="button"
          className="btn btn-lg btn-primary"
          data-dismiss="modal"
          data-testid="footer-close-button"
        >
          {translations.Close}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTermsAndConditions;
