import React from "react";
import PagesetBox from "@common/PagesetBox";

const List = ({ items, product, translations, type, fetchPagesets }) => (
  <div className="row pageset-list-container">
    {items.length ? (
      items.map((data) => (
        <PagesetBox
          key={data.id}
          product={product}
          data={data}
          type={type}
          col={6}
          fetchPagesets={fetchPagesets}
        />
      ))
    ) : (
      <div className="col-xs-12">
        <p className="no-data-message">
          {`${translations.MYTD_thereAreNoLbl1} ${type} ${translations.MYTD_thereAreNoLbl2}`}
        </p>
      </div>
    )}
  </div>
);

export default List;
