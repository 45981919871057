import { React, useState } from "react";

import Translation from "@common/Translation";

const MessageBankBanner = () => {
  const bannerPath = "/assets/messagebank/img/bch-logo.png";
  const [bannerOpen, setBannerOpen] = useState(true);
  const toggleBanner = () => {
    setBannerOpen((prev) => !prev);
  };

  return (
    <div
      data-testid={"main-wrapper"}
      className={`container-full bch ${bannerOpen ? "" : "closed"}`}
    >
      <div className="container">
        <div>
          <a
            className={bannerOpen ? "close" : "open"}
            onClick={toggleBanner}
            role="button"
          ></a>
          {bannerOpen ? (
            <div className="col-md-8 col-md-offset-2 text-center">
              <img
                src={bannerPath}
                className="img-responsive center-block"
                alt="BCH Banner"
              ></img>
              <hr />
              <Translation translate="MB_intro_1" />
            </div>
          ) : (
            <div className="col-md-8 col-md-offset-2 text-center">
              <Translation translate="MYTD_msgBankIntroTxt1" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default MessageBankBanner;
