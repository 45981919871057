import React from "react";

interface DownloadComponentProps {
  resourceType: string;
  mainUrl: string;
  mainUrlText: string;
  description: string;
}
const DownloadComponent: React.FC<DownloadComponentProps> = ({
  resourceType,
  mainUrl,
  mainUrlText,
  description,
}) => {
  return (
    <div className="panel panel-default">
      <div className="panel-body">
        <div className="file-type-pdf" title={resourceType}></div>
        <div className="resource">
          <a href={mainUrl} target="_blank" rel="noreferrer">
            {mainUrlText}
          </a>
          <span className="desc">
            <span>{description}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default DownloadComponent;
