import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store";
import AvailableUpdatesModal from "./AvailableUpdatesModal";
import ProfileDropdown from "./ProfileDropdown";
import useLanguageData from "./hooks/useLanguageData";
import { redirectToLogin, redirectToRegister } from "@services";
import { fetchUserDetail } from "actions";

interface HeaderProps {
  translations: {
    Updates_available_: string;
    Review_All: string;
    Create_account: string;
    Log_in: string;
    Close: string;
  };
}

const Header: React.FC<HeaderProps> = ({ translations }) => {
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.user);
  const { languageCode } = useSelector(
    (state: RootState) => state.languageData,
  );

  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const { languageData, handleLanguageChange } = useLanguageData();

  const openModal = () => setModalVisible(true);

  useEffect(() => {
    if (user.isAuthenticated) {
      dispatch(fetchUserDetail(languageCode));
    }
  }, [dispatch, user.isAuthenticated, languageCode]);

  const languageOptions = useMemo(
    () =>
      languageData?.LanguageInfos?.map((language, index) => (
        <React.Fragment key={language.LanguageCode}>
          <li className="radio custom radio-primary">
            <input
              name="culture"
              id={language.LanguageCode}
              value={language.LanguageCode}
              type="radio"
              checked={languageData.LanguageCode === language.LanguageCode}
              onChange={() => {
                handleLanguageChange(
                  language.LanguageCode,
                  language.LanguageName,
                );
              }}
            />
            <label htmlFor={language.LanguageCode}>
              {language.LanguageName}
            </label>
          </li>
          {index < languageData.LanguageInfos.length - 1 && (
            <li className="divider" role="separator"></li>
          )}
        </React.Fragment>
      )),
    [languageData, handleLanguageChange],
  );

  return (
    <div id="headerNav01" className="navbar" role="navigation">
      <div className="container">
        <div className="navbar-header hidden-xs hidden-sm">
          {translations.Updates_available_}{" "}
          <a
            href="#availableUpdatesModal"
            onClick={openModal}
            data-toggle="modal"
            aria-haspopup="true"
            aria-expanded={isModalVisible}
          >
            {translations.Review_All}
          </a>
        </div>
        <div className="navbar-collapse collapse">
          <ul className="nav navbar-nav navbar-right f16">
            {languageData && (
              <li id="sc-dd" className="dropdown" key={"sc-dd"}>
                <a
                  href="#"
                  title=""
                  data-toggle="dropdown"
                  className="dropdown-toggle sc-flag"
                >
                  {languageData.LanguageName} <span className="caret"></span>
                </a>
                <ul className="dropdown-menu dropdown-menu-left f16">
                  {languageOptions}
                </ul>
              </li>
            )}

            {!user.isAuthenticated ? (
              <>
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      redirectToRegister();
                    }}
                  >
                    {translations.Create_account}
                  </a>
                </li>

                <li key="login-li">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      redirectToLogin();
                    }}
                  >
                    {translations.Log_in}
                  </a>
                </li>
              </>
            ) : (
              <ProfileDropdown user={user} />
            )}
          </ul>
        </div>
      </div>

      {isModalVisible && <AvailableUpdatesModal resources={translations} />}
    </div>
  );
};

export default Header;
