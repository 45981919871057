import React, { useRef, useState, useEffect } from "react";
import { Message, Tag } from "../types";

interface TagsProps {
  message: Message;
  onTagAdd: (message: Message, tagName: string) => void;
  onTagDelete: (message: Message, tag: Tag) => void;
}

const Tags: React.FC<TagsProps> = ({ message, onTagAdd, onTagDelete }) => {
  const [showTagForm, setShowTagForm] = useState<boolean>(false);
  const [tagName, setTagName] = useState<string>("");
  const tagInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (showTagForm && tagInputRef.current) {
      tagInputRef.current.focus();
    }
  }, [showTagForm]);

  const handleTagAdd = () => {
    if (tagName.trim()) {
      onTagAdd(message, tagName);
      setTagName("");
    }
    setShowTagForm(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleTagAdd();
    }
  };

  const toggleTagForm = () => setShowTagForm((prev) => !prev);

  return (
    <div className="tag-list">
      <div className="form-inline">
        <button
          className="btn message-icon add-tag"
          onClick={toggleTagForm}
          title="Add Tag"
        ></button>
        {showTagForm && (
          <div>
            <input
              type="text"
              className="form-control input-sm"
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
              onKeyDown={handleKeyDown}
              onBlur={handleTagAdd}
              ref={tagInputRef}
            />
          </div>
        )}
      </div>
      <div className="form-group tags">
        {message.tags.map((tag, index) => (
          <span key={`tag_${index}`} className="tag">
            {tag.name}
            <span
              className="message-icon remove-tag"
              onClick={() => onTagDelete(message, tag)}
              title="Delete Tag"
            ></span>
          </span>
        ))}
      </div>
    </div>
  );
};

export default Tags;
