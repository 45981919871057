import React from "react";

interface FormErrorsProps {
  formErrors: Record<string, string>;
}

const FormErrors: React.FC<FormErrorsProps> = ({ formErrors }) => {
  return (
    <span id="form-errors" className="field-validation-error">
      <ul className="margin-bottom-md">
        {Object.keys(formErrors).map((fieldName, i) => {
          if (formErrors[fieldName].length > 0) {
            return (
              <li className="has-error" key={i}>
                {formErrors[fieldName]}
              </li>
            );
          } else {
            return null;
          }
        })}
      </ul>
    </span>
  );
};

export default FormErrors;
