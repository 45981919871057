import React, { FC, useEffect, useState } from "react";
import AppRouter from "./route/app-router";
import { useDispatch } from "react-redux";
import { AuthProvider } from "@common/AuthContext";
import "./styles/App.css";
import { Toaster } from "react-hot-toast";
import { checkAuthStatus } from "@services";
import { setAuthentication } from "./actions";

let didInit = false;

const App: FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!didInit) {
      didInit = true;
      const checkAuthState = async () => {
        try {
          const data = await checkAuthStatus();
          setIsAuthenticated(data);
          dispatch(setAuthentication(data));
        } catch (error) {
          console.error("Error checking auth status:", error);
          setIsAuthenticated(false);
        } finally {
          setIsLoading(false);
        }
      };

      checkAuthState();
    }
  }, [dispatch]);

  if (isLoading) {
    return <div>loading...</div>;
  }

  return (
    <AuthProvider initialAuthStatus={isAuthenticated}>
      <Toaster />
      <AppRouter />
    </AuthProvider>
  );
};

export default App;
