export const downloadInnerRoutes = [
  { path: "accessible-literacy-learning", pageKey: "ALL" },
  { path: "beam", pageKey: "Beam" },
  { path: "boardmaker-student-center-app", pageKey: "BMSC" },
  {
    path: "classic-tobii-gaze-interaction-software",
    pageKey: "ClassicTobiiGazeInteractionSoftware",
  },
  { path: "communicator-5", pageKey: "Communicator5" },
  { path: "gaze-point", pageKey: "GazePoint" },
  { path: "gaze-viewer", pageKey: "GazeViewer" },
  { path: "mirror-for-android", pageKey: "MirrorAndroid" },
  { path: "pathways", pageKey: "Pathways" },
  { path: "pceye", pageKey: "PCEyeCC" },
  { path: "td-snap", pageKey: "SnapCoreFirst" },
  { path: "snap-scene", pageKey: "SnapScene" },
  { path: "windows-control", pageKey: "WindowsControl" },
  { path: "communicator-4", pageKey: "Communicator4" },
  { path: "liter-aacy", pageKey: "LiterAACy" },
  { path: "sono-key", pageKey: "SonoKey" },
  { path: "sono-primo", pageKey: "SonoPrimo" },
  { path: "compass", pageKey: "Compass" },
  { path: "sono-flex", pageKey: "SonoFlex" },
  { path: "sono-lexis", pageKey: "SonoLexis" },
  { path: "sono-scribe", pageKey: "SonoScribe" },
  { path: "on-screen-keyboard-app", pageKey: "OnscreenKeyboardApp" },
  { path: "series-5-converter", pageKey: "Series5Converter" },
  { path: "talk-together", pageKey: "TalkTogether" },
  { path: "pcs-symbols", pageKey: "PCSSymbols" },
  { path: "tablet-talk", pageKey: "TabletTalk" },
  { path: "windows-compatibility", pageKey: "WindowsCompatibility" },
];

export const storeRoutes = [
  { path: "snap", pageKey: "SnapCoreFirst" },
  { path: "snap-core-first-perpetual", pageKey: "SnapCoreFirstPerpetual" },
  { path: "communicator", pageKey: "Communicator5" },
  { path: "pathways", pageKey: "Pathways" },
  { path: "snap-scene", pageKey: "SnapScene" },
  { path: "all", pageKey: "ALL" },
  { path: "early-intervention", pageKey: "EarlyIntervention" },
  { path: "gaze-viewer", pageKey: "GazeViewer" },
];

export const productsOverviewRoutes = [
  { path: "snap", pageKey: "TD Snap" },
  { path: "communicator", pageKey: "Communicator" },
  { path: "snapscene", pageKey: "Snap Scene" },
  { path: "all", pageKey: "ALL" },
];
