import { postData, fetchData } from "./base-service";

const MESSAGE_BANK_API_ENDPOINTS = {
  fetchMessages: (langCode) => `/v1/message-banking/messages?uic=${langCode}`,
  fetchLanguages: (langCode) => `/v1/message-banking/languages?uic=${langCode}`,
};

export const getMessages = (langCode, payload) => {
  return postData(MESSAGE_BANK_API_ENDPOINTS.fetchMessages(langCode), payload);
};

export const getMessageBankLanguages = (langCode) => {
  return fetchData(MESSAGE_BANK_API_ENDPOINTS.fetchLanguages(langCode));
};
