import React, { useState, useEffect } from "react";
import TabContent from "./TabContent";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { MythResource } from "types";
import { getMythsResources } from "@services";

const MythsAndResearch = () => {
  const { languageCode } = useSelector(
    (state: RootState) => state.languageData,
  );
  const [mythResources, setMythResources] = useState<MythResource>();
  const [activeTab, setActiveTab] = useState<string>("Myths");

  useEffect(() => {
    const fetchMythResources = async () => {
      const data = await getMythsResources(languageCode);
      setMythResources(data);
    };
    fetchMythResources();
  }, [languageCode]);

  const handleTabClick = (tab: string, e: React.MouseEvent) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  return (
    <>
      <div className="container">
        <h1>{mythResources?.title}</h1>
      </div>
      <div className="container">
        <p>{mythResources?.introduction}</p>
      </div>
      <div id="tabs">
        <div role="navigation">
          <div className="container pills-container">
            <div className="row visible-xs">
              <div className="col-xs-12"></div>
            </div>
            <div className="collapse navbar-collapse" id="collapse-navbar">
              <ul className="nav nav-pills">
                {mythResources?.resources.map((resourceItem) => {
                  return (
                    <li className="" key={resourceItem.tab}>
                      <a
                        data-testid="myth"
                        href="#"
                        onClick={(e) => {
                          handleTabClick(resourceItem.tab, e);
                        }}
                      >
                        {resourceItem.tab}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        {mythResources?.resources
          .filter((resources) => resources.tab === activeTab)
          .map((resourceItem) => (
            <TabContent key={resourceItem.tab} links={resourceItem.links} />
          ))}
      </div>
    </>
  );
};

export default MythsAndResearch;
