import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useParams } from "react-router-dom";

import TabLink from "@common/TabLink/Index";
import Breadcrumb from "@common/Breadcrumb";
import Translation from "@common/Translation";
import { getMyStuffData, getTabs } from "@services/my-stuff-service";
import { generateBreadcrumbItems } from "@helpers/utilities";
import MessageBankBanner from "components/prelogin/ProductsOverview/MessageBankBanner";

const mapPathWithTab = (tabName) => {
  const normalizedTabName = String(tabName).toLowerCase();

  const mapping = {
    mytd_overviewlbl: "",
    mytd_bookslbl: "books",
    mytd_studentslbl: "students",
    mytd_pagesetslbl: "pagesets",
    mytd_backupslbl: "backups",
    mytd_deviceslbl: "attachments",
    mytd_supportlbl: "",
    usage_reports: "reports",
    mytd_mbtablblmymessages: "messages",
    mytd_productslbl: "products",
  };

  return mapping[normalizedTabName] ?? normalizedTabName;
};

export const MyStuff = () => {
  const { translations } = useSelector((state) => state.translations);
  const { languageCode } = useSelector((state) => state.languageData);
  const location = useLocation();
  const { product } = useParams();

  const [myStuffData, setMyStuffData] = useState({});
  const [tabsData, setTabsData] = useState({ tabs: [] });

  useEffect(() => {
    const fetchData = async () => {
      const data = await getMyStuffData(languageCode, product);
      setMyStuffData(data);
    };
    fetchData();
  }, [languageCode, product]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getTabs(languageCode, product);
      setTabsData(data);
    };
    fetchData();
  }, [languageCode, product]);

  if (!tabsData.tabs || tabsData.tabs.length === 0) {
    return <div>No data available</div>;
  }

  const TAB_ROUTES = tabsData.tabs.map((tab) => {
    const pathName = mapPathWithTab(tab.key);
    const path = pathName ? `/${pathName}` : "";

    return {
      path: `/mystuff/${product}${path}`,
      label: tab.translation,
      end: !pathName,
    };
  });

  const breadcrumbItems = generateBreadcrumbItems({
    baseHref: `/mystuff/${product}`,
    title: tabsData.title,
    routes: TAB_ROUTES,
    pathname: location.pathname,
  });

  if (!translations) return null;

  return (
    <>
      {product === "messagebank" && <MessageBankBanner />}
      <div className="container-full">
        <div className="container myStuff-header">
          <h1>{tabsData.title}</h1>
          <Breadcrumb items={breadcrumbItems} />
        </div>
      </div>
      <div className="container-full">
        <div className="container" style={{ paddingBottom: 0 }}>
          <div className="tab-link-set">
            {TAB_ROUTES.map((route) => (
              <TabLink key={route.path} to={route.path} end={route.end}>
                {route.label}
              </TabLink>
            ))}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container">
          <Outlet
            context={{
              data: myStuffData,
              product,
              languageCode,
              translations,
              tabsData,
            }}
          />
        </div>
      </div>
      {product === "professionals" &&
        !location.pathname.includes("products") && (
          <div className="container-full">
            <div className="container pros-intro-text">
              <Translation translate="MYTD_tobiiProaccessMsg" />
            </div>
          </div>
        )}
    </>
  );
};

export default MyStuff;
