import React, { useState } from "react";
import TableHeader from "./TableHeader";
import { Language, Message, Tag } from "../types";
import TableRow from "./TableRow";

interface TableProps {
  sort: string;
  handleSortChange: (column: string) => void;
  messages: Message[];
  availableLangs: Language[];
  onMessageSelect: (message: Message) => void;
  onSelectAll: (selectAll: boolean) => void;
  setMessages: (
    messages: Message[] | ((prevMessages: Message[]) => Message[]),
  ) => void;
}

const Table: React.FC<TableProps> = ({
  handleSortChange,
  sort,
  messages,
  onMessageSelect,
  onSelectAll,
  availableLangs,
  setMessages,
}) => {
  const [bulkAction, setBulkAction] = useState({
    inProgress: false,
    complete: false,
  });
  const areAllSelected = messages.every((msg) => msg.selected);

  const onMessageLabelUpdate = (message: Message, newLabel: string) => {
    if (message.label !== newLabel && newLabel.trim().length > 0) {
      const updatedMessage = { ...message, label: newLabel };

      // TODO: Handle update title API call
      console.log("Updated message:", updatedMessage);

      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === message.id ? updatedMessage : msg,
        ),
      );
    }
  };

  const onMessageTranscriptionTextUpdate = (
    message: Message,
    newTranscription: string,
  ) => {
    if (
      message.transcription.text !== newTranscription &&
      newTranscription.trim().length > 0
    ) {
      const updatedMessage = {
        ...message,
        transcription: {
          ...message.transcription,
          text: newTranscription,
          verified: true,
        },
      };

      // TODO: Handle update text api call
      console.log("Updated message:", updatedMessage);

      setMessages((prevMessages) => {
        const messageIndex = prevMessages.findIndex(
          (msg) => msg.id === message.id,
        );
        if (messageIndex === -1) return prevMessages;

        const updatedMessages = [...prevMessages];
        updatedMessages[messageIndex] = updatedMessage;
        return updatedMessages;
      });
    }

    return message;
  };

  const onConfidenceClick = (message: Message) => {
    if (!message.transcription.verified) {
      const updatedMessage = {
        ...message,
        transcription: {
          ...message.transcription,
          verified: true,
        },
      };

      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === message.id ? updatedMessage : msg,
        ),
      );

      // TODO: Handle API call to update the transcription's verified status
      console.log("Updated message:", updatedMessage);
    }
  };

  const onTagAdd = (message: Message, tagName: string) => {
    const updatedMessages = messages.map((msg) =>
      msg.id === message.id
        ? {
            ...msg,
            tags: [...msg.tags, { id: `t${Date.now()}`, name: tagName }],
          }
        : msg,
    );
    setMessages(updatedMessages);
    console.log("Tag added:", updatedMessages);
    // TODO: Replace with API call
  };

  const onTagDelete = (message: Message, tag: Tag) => {
    const updatedMessages = messages.map((msg) =>
      msg.id === message.id
        ? {
            ...msg,
            tags: msg.tags.filter((t) => t.id !== tag.id),
          }
        : msg,
    );
    setMessages(updatedMessages);
    console.log("Tag deleted:", updatedMessages);
    // TODO: Replace with API call
  };

  const onMessageDelete = async (message: Message) => {
    setBulkAction({ inProgress: true, complete: false });

    setMessages((prevMessages) =>
      prevMessages.filter((msg) => msg.id !== message.id),
    );

    setBulkAction({ inProgress: false, complete: true });

    // TODO: add api call to delete message
  };

  const resetBulkAction = () => {
    setBulkAction({ inProgress: false, complete: false });
  };

  const onSaveRetry = (
    e: React.MouseEvent<HTMLAnchorElement>,
    message: Message,
  ) => {
    e.preventDefault();

    const prevState = messages.find((msg) => msg.id === message.id);
    const nextState = { ...message };

    // TODO: Replace with API call to retry the save operation
    console.log("Retrying save:", { prevState, nextState });

    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === message.id ? { ...nextState } : msg,
      ),
    );
  };

  const onSaveRetryCancel = (
    e: React.MouseEvent<HTMLAnchorElement>,
    message: Message,
  ) => {
    e.preventDefault();

    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === message.id ? { ...msg, saveFailed: false } : msg,
      ),
    );
  };

  return (
    <table className="table table-message-list">
      <TableHeader
        sort={sort}
        onSortChange={handleSortChange}
        onSelectAll={onSelectAll}
        allSelected={areAllSelected}
      />
      <tbody>
        {messages.map((message) => (
          <TableRow
            key={message.id}
            message={message}
            onMessageSelect={onMessageSelect}
            onMessageLabelUpdate={onMessageLabelUpdate}
            availableLangs={availableLangs}
            onMessageTranscriptionTextUpdate={onMessageTranscriptionTextUpdate}
            onConfidenceClick={onConfidenceClick}
            onTagAdd={onTagAdd}
            onTagDelete={onTagDelete}
            onMessageDelete={onMessageDelete}
            bulkAction={bulkAction}
            resetBulkAction={resetBulkAction}
            onSaveRetry={onSaveRetry}
            onSaveRetryCancel={onSaveRetryCancel}
          />
        ))}
      </tbody>
    </table>
  );
};

export default Table;
