import { createAction } from "@reduxjs/toolkit";
import { getUserProfile } from "@services/user-service";
import { AppDispatch } from "store";

interface UserProfile {
  id: string;
  name: string;
  email: string;
}

export const setUserDetails = createAction<UserProfile>("users/setUserDetail");

export const setUserDataFailure = createAction<Error>(
  "users/setUserDataFailure",
);
export const setAuthentication = createAction<boolean>(
  "auth/setAuthentication",
);

export const fetchUserDetail =
  (langCode: string) => async (dispatch: AppDispatch) => {
    try {
      const data: UserProfile = await getUserProfile(langCode);

      dispatch(setUserDetails(data));
    } catch (error) {
      dispatch(
        setUserDataFailure(
          error instanceof Error ? error : new Error("Unknown error"),
        ),
      );
    }
  };
