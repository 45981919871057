import React, { useState, useEffect, useRef } from "react";
import { Message } from "../types";
import Translation from "@common/Translation";

interface MessageTranscriptionTextProps {
  message: Message;
  onMessageTranscriptionTextUpdate: (message: Message, newText: string) => void;
}

const MessageTranscriptionText: React.FC<MessageTranscriptionTextProps> = ({
  message,
  onMessageTranscriptionTextUpdate,
}) => {
  const [transcriptionText, setTranscriptionText] = useState<string>(
    message.transcription.text || "",
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const textInputRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (editMode) textInputRef.current?.focus();
  }, [editMode]);

  const toggleEditMode = (e: React.MouseEvent) => {
    e.preventDefault();
    setEditMode((prev) => !prev);
  };

  const confirmUpdate = (
    e:
      | React.FocusEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    const newText = (e.target as HTMLTextAreaElement).value
      .replace(/\s{2,}/g, " ")
      .trim();
    setEditMode(false);
    setTranscriptionText(newText);
    onMessageTranscriptionTextUpdate(message, newText);
  };

  return (
    <div>
      {editMode ? (
        <textarea
          className="form-control"
          rows={5}
          value={transcriptionText}
          onChange={(e) => setTranscriptionText(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && confirmUpdate(e)}
          onBlur={confirmUpdate}
          ref={textInputRef}
        />
      ) : !message.transcription.text ? (
        <button className="btn btn-xs" onClick={toggleEditMode}>
          <Translation translate="MYTD_addLbl" />
        </button>
      ) : (
        <a href="#" onClick={toggleEditMode}>
          {message.transcription.text}
        </a>
      )}
    </div>
  );
};

export default MessageTranscriptionText;
