import React from "react";
import RawHTML from "@common/RawHTML/Index";
import ProductBuyButton from "./Common/BuyButton";

const OneTimeSinglePurchase = ({
  oneTimeSinglePurchase,
  translations,
  product,
}) => {
  if (product === "GazeViewer")
    return (
      <>
        {oneTimeSinglePurchase.applications.map((app, index) => (
          <div
            id="shopNow"
            className={
              product === "GazeViewer" ? "container" : "container-full"
            }
            key={`${app.type}-${index}`}
          >
            <div className="container text-center">
              <div className="row">
                <div className="col-sm-8 col-sm-push-2">
                  <h2>{app.type}</h2>
                  <RawHTML htmlContent={app.description} />
                </div>
              </div>
              <div className="row jump-links">
                {app.apps.map((insideApp, index) => (
                  <div
                    className="col-sm-6 col-sm-push-3 col-md-4 col-md-push-4"
                    key={index}
                  >
                    <div className="well-product-link">
                      <div className="match_desc">
                        <div className="hidden-xs icon icon-store icon-68"></div>
                        <h3>{insideApp.title}</h3>
                        <p>{insideApp.description}</p>
                      </div>
                      <div className="match_cta pricing">
                        <hr />
                        <h3>{insideApp.price}</h3>
                        <h4>{insideApp.priceNoteHeader}</h4>
                        <ProductBuyButton
                          text={translations.Buy_Now}
                          className="btn btn-lg btn-primary"
                          link={insideApp.links}
                          data-testid="buy-button"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </>
    );
  else
    return (
      <div className="container-full" id="shopNow">
        <div className="container text-center">
          <h2>{oneTimeSinglePurchase?.applications[0]?.type}</h2>
          <p>{oneTimeSinglePurchase?.applications[0]?.description}</p>
          <p className="note">{oneTimeSinglePurchase?.headerNote}</p>
          <div className="row">
            <div className="col-md-6">
              <div
                className="well jump-links text-center"
                style={{ height: "290px" }}
              >
                <div className="icon icon-store icon-68 data-icon"></div>
                <h3>
                  {oneTimeSinglePurchase?.applications[0]?.apps[0]?.title}
                </h3>
                <a
                  href="/Store/BuyNow/snapcorefirstperpetuallicense"
                  target="_blank"
                  className="btn btn-primary btn-lg"
                >
                  {translations.Buy_or_Redeem_Now}
                </a>
              </div>
            </div>
            <div
              className="well jump-links text-center"
              style={{ height: "290px" }}
            >
              <div className="icon icon-download-installer icon-68 data-icon"></div>
              <h3>
                {oneTimeSinglePurchase?.applications[0]?.apps[1]?.availability}
              </h3>
              <a
                href={oneTimeSinglePurchase?.applications[0]?.apps[1]?.links}
                target="_blank"
                rel="noreferrer"
                className="btn btn-lg btn-windows"
                data-testid="download-button"
              >
                <img
                  src="/assets/images/btn/badge-download-for-windows.svg"
                  alt={
                    oneTimeSinglePurchase?.applications[0]?.apps[1]
                      ?.availability
                  }
                  className="margin-right-none"
                />
              </a>
              <p>{translations.Log_in_to_myTobiiDynavox_during_app_start_up}</p>
            </div>
          </div>
        </div>
      </div>
    );
};

export default OneTimeSinglePurchase;
