import React from "react";
import Translation from "../components/common/Translation";
import {
  addPagesetToMyStuff,
  downloadBackupById,
  downloadCompassPagesetById,
  downloadPagesetById,
  redirectToLogin,
} from "../services";

const languageMap = {
  ar: "Arabic",
  da: "Danish",
  de: "German",
  "en-US": "English",
  es: "Spanish",
  fr: "French",
  nl: "Dutch",
  nb: "Norwegian",
  pt: "Portuguese",
  sv: "Swedish",
  "zh-Hans": "Chinese",
  it: "Italian",
  is: "Icelandic",
  cs: "Czech",
};

const dynamicSort = (property, order) => (a, b) =>
  (a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0) * order;

const setInitialLangFilter = (culture) => {
  const sessionLangsArray =
    JSON.parse(sessionStorage.getItem("sessionLangsArray")) || null;

  return sessionLangsArray && sessionLangsArray.length > 0
    ? sessionLangsArray
    : [languageMap[culture] || "English"];
};

const applyFilter = (selectedLanguages, products) => {
  sessionStorage.setItem(
    "sessionLangsArray",
    JSON.stringify(selectedLanguages),
  );

  return products.filter((product) =>
    product.languages.some((lang) => selectedLanguages.includes(lang.name)),
  );
};

const downloadRoutes = [
  { path: "td-snap", name: "TD Snap" },
  { path: "communicator-5", name: "Communicator" },
  { path: "accessible-literacy-learning", name: "ALL" },
  { path: "snap-scene", name: "Snap Scene" },
];

const storeRoutes = [
  { path: "snap", name: "SnapCoreFirst" },
  { path: "communicator", name: "Communicator_5" },
  { path: "communicator", name: "Communicator" },
  { path: "pathways", name: "Pathways" },
  { path: "snap-scene", name: "Snap_Scene" },
  { path: "all", name: "ALL" },
  { path: "early-intervention", name: "Early_Intervention" },
  { path: "gaze-viewer", name: "Gaze_Viewer" },
];

const documentationRoutes = [
  { path: "TDSnap", name: "TD Snap" },
  { path: "Communicator", name: "Communicator" },
  { path: "ALL", name: "ALL" },
  { path: "ALL", name: "Accessible Literacy Learning (ALL)" },
  { path: "SnapScene", name: "Snap Scene" },
  { path: "MyTobiiDynavoxWebsite", name: "myTobiiDynavox Website" },
  { path: "MessageBank", name: "Message Bank" },
  { path: "Compass", name: "Compass" },
  { path: "Access", name: "Access" },
  { path: "Devices", name: "Devices" },
];

const pathByName = (name, routeType) => {
  let routeArray;

  switch (routeType) {
    case "download":
      routeArray = downloadRoutes;
      break;
    case "store":
      routeArray = storeRoutes;
      break;
    case "documentation":
      routeArray = documentationRoutes;
      break;
    default:
      return null;
  }

  const route = routeArray.find((route) => route.name === name);
  return route ? route.path : null;
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const handleDownload = (id, product = "", type = "pagesets") => {
  if (type === "backups") {
    downloadBackupById(id, product);
  } else {
    if (product.toLowerCase() === "compass") {
      downloadCompassPagesetById(id);
    } else {
      downloadPagesetById(id);
    }
  }
};

const handleAdd = (isAuthenticated, product, pagesetId) => {
  if (!isAuthenticated) {
    redirectToLogin();
    return;
  }
  addPagesetToMyStuff(product, pagesetId);
};

const determineImagePath = (imageUrl, software) => {
  if (imageUrl) {
    return `/azure/pagesetthumbnails/${imageUrl}?w=237`;
  } else if (software === "SnapCoreFirst") {
    return "/assets/images/pagesets/snap-backup-new.png";
  } else if (software === "SnapScene") {
    return "/assets/images/pagesets/snap-scene-backup.png";
  } else {
    return "/assets/images/pagesets/all-backup.png";
  }
};

const getImageSrc = (software, imageSrc) => {
  switch (software) {
    case "ALL":
    case "SnapCoreFirst":
    case "SnapScene":
      return imageSrc;
    case "Communicator":
      return "/assets/images/pagesets/communicator-pageset.png";
    case "Compass":
      return "/assets/images/pagesets/compass-pageset.png";
    case "SymbolMate":
      return "/assets/images/pagesets/symbolmate-pageset.png";
    default:
      return "/assets/images/pagesets/tobii-pageset.png";
  }
};

const localeFixerMoment = (locale) => {
  let newLocale;
  switch (locale) {
    case "en-US":
      newLocale = "en";
      break;
    case "zh-Hans":
      newLocale = "zh-cn";
      break;
    default:
      newLocale = locale;
      break;
  }
  return newLocale;
};

const getCookie = (cname) => {
  var t = document.cookie.match("(^|;) ?" + cname + "=([^;]*)(;|$)");
  return t ? t[2] : null;
};

export const momentLocaleFix = localeFixerMoment(getCookie("culture"));

const filterPagesets = (pagesets, filters, pagesetFilters) => {
  return pagesets.filter((pageset) => {
    const matchesText = pageset.title
      .toLowerCase()
      .includes(filters.text.toLowerCase());

    const matchesTags =
      (filters.optionOneChecked && pageset.tags.includes(pagesetFilters[0])) ||
      (filters.optionTwoChecked && pageset.tags.includes(pagesetFilters[1])) ||
      (!filters.optionOneChecked && !filters.optionTwoChecked); // Show all if no checkboxes are checked

    return matchesText && matchesTags;
  });
};

const filterBackups = (backups, filters) => {
  return backups.filter((backup) => {
    return backup.title.toLowerCase().includes(filters.text.toLowerCase());
  });
};

const paginateItems = (items, itemOffset, itemsPerPage) => {
  return items.slice(itemOffset, itemOffset + itemsPerPage);
};

export const generateBreadcrumbItems = ({
  baseHref,
  title,
  routes,
  pathname,
}) => {
  const baseItems = [
    {
      href: baseHref,
      label: title,
    },
  ];

  const activeRoute = routes.find((route) => pathname === route.path);

  if (activeRoute) {
    baseItems.push({ label: activeRoute.label });
  }

  return baseItems;
};

const supportedFileTypesByProduct = {
  snap: {
    pagesets: [".sps", ".spb"],
    backups: [".sub"],
  },
  compass: {
    pagesets: [".bzf", ".osk", ".pak"],
  },
  communicator: {
    pagesets: [".cdd", ".cddx"],
    backups: [".vse"],
  },
  snapscene: {
    backups: [".ssp"],
  },
};

const getSupportedFileTypes = (product, type) => {
  if (
    supportedFileTypesByProduct[product] &&
    supportedFileTypesByProduct[product][type]
  ) {
    return supportedFileTypesByProduct[product][type];
  } else {
    return [];
  }
};

const emailRegex =
  /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const emailMessaging = {
  invalidEmail: <Translation translate="MYTD_emailFormatIncorrectErr" />,
  emailNotFound: <Translation translate="MYTD_emailNotFoundErr" />,
  emailAlreadyInList: <Translation translate="MYTD_emailAlreadyUsedErr" />,
};

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export {
  capitalizeFirstLetter,
  dynamicSort,
  setInitialLangFilter,
  applyFilter,
  pathByName,
  formatBytes,
  handleDownload,
  handleAdd,
  getImageSrc,
  determineImagePath,
  localeFixerMoment,
  getCookie,
  filterPagesets,
  filterBackups,
  paginateItems,
  getSupportedFileTypes,
  emailRegex,
  emailMessaging,
};
