import React from "react";
import ProductBuyButton from "./BuyButton";

const SystemBuyButton = ({ system, translations, link }) => {
  const getClassName = (system) => {
    switch (system) {
      case "I_Series":
      case "T_Series":
      case "Windows":
      case "Android":
        return `btn btn-lg btn-andoid`;
      case "AppStore":
      case "iTunes":
      case "T-Series":
        return "btn btn-lg btn-apple";

      default:
        return "btn btn-lg";
    }
  };

  const getImageSrc = (system) => {
    switch (system) {
      case "I_Series":
        return "/assets/images/btn/badge-buy-now-for-i-series.svg";
      case "T_Series":
      case "T-Series":
        return "/assets/images/btn/badge-buy-now-for-t-series.svg";
      case "Windows":
        return "/assets/images/btn/badge-buy-now-for-windows.svg";
      case "AppStore":
        return "/assets/images/btn/badge-download-on-the-app-store.svg";
      case "Android":
        return "/assets/images/btn/badge-download-for-android-phones.svg";
      case "iTunes":
        return "/assets/images/btn/badge-download-from-itunes.svg";
      default:
        return "/assets/images/btn/default-badge.svg";
    }
  };

  const getImageAltText = (system) => {
    switch (system) {
      case "I_Series":
        return translations.Buy_Now_for_I_Series;
      case "T_Series":
      case "T-Series":
        return translations.Buy_Now_for_T_Series;
      case "Windows":
        return translations.Buy_Now_for_Windows;
      case "AppStore":
        return translations.Download_on_the_App_Store;
      case "Android":
        return translations.Download_on_the_App_Store;
      case "iTunes":
        return translations.Download_From_iTunes;
      default:
        return "";
    }
  };

  const altText = getImageAltText(system);
  const imgSrc = getImageSrc(system);
  const className = getClassName(system);

  return (
    <ProductBuyButton
      className={className}
      imgSrc={imgSrc}
      altText={altText}
      link={link}
    />
  );
};

export default SystemBuyButton;
