import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";

import { cultureService } from "@services";
import { setLanguage } from "../../../../actions";

type LanguageInfo = {
  LanguageCode: string;
  LanguageName: string;
};

interface LanguageData {
  LanguageCode: string;
  LanguageName: string;
  LanguageInfos: LanguageInfo[];
}

const useLanguageData = () => {
  const dispatch = useDispatch();
  const [languageData, setLanguageData] = useState<LanguageData | null>(null);

  useEffect(() => {
    const data = cultureService.getCultureOptions() as LanguageData | null;
    setLanguageData(data);
    if (data) {
      dispatch(
        setLanguage({
          languageCode: data.LanguageCode,
          languageName: data.LanguageName,
        }),
      );
    }
  }, [dispatch]);

  const handleLanguageChange = useCallback(
    (languageCode: string, languageName: string) => {
      dispatch(setLanguage({ languageCode, languageName }));
      cultureService.setCulture(languageCode);
    },
    [dispatch],
  );

  return { languageData, handleLanguageChange };
};

export default useLanguageData;
