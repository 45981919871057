import React from "react";
import Translation from "@common/Translation";

interface TableHeaderProps {
  sort: string;
  onSortChange: (column: string) => void;
  onSelectAll: (selectAll: boolean) => void;
  allSelected: boolean;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  sort,
  onSortChange,
  onSelectAll,
  allSelected,
}) => {
  const getSortIndicator = (column: string) =>
    sort.includes(column) ? (
      sort.startsWith("-") ? (
        <span className="caret desc"></span>
      ) : (
        <span className="caret"></span>
      )
    ) : null;

  const getHeaderClass = (column: string) =>
    sort.includes(column) ? "col-xs-3 sortable sorted" : "col-xs-3 sortable";

  return (
    <thead>
      <tr>
        {/* Title Column */}
        <th className={getHeaderClass("label")}>
          <input
            checked={allSelected}
            onChange={(e) => onSelectAll(e.target.checked)}
            type="checkbox"
          />
          <span onClick={() => onSortChange("label")}>
            <Translation translate="MYTD_titleTxt" />{" "}
            {getSortIndicator("label")}
          </span>
        </th>

        {/* Text Column */}
        <th className={getHeaderClass("text")}>
          <span onClick={() => onSortChange("text")}>
            <Translation translate="MYTD_MBListText" />{" "}
            {getSortIndicator("text")}
          </span>
        </th>

        {/* Confidence Column */}
        <th className="col-xs-1 text-center">
          <span className="hidden-xs hidden-sm hidden-md">
            <Translation translate="MYTD_MBListConfidence" />
          </span>
          <span className="hidden-lg">
            <Translation translate="MYTD_MBListConf" />
          </span>
        </th>

        {/* Recording Column */}
        <th className="col-xs-1">
          <span className="hidden-xs hidden-sm hidden-md">
            <Translation translate="MYTD_MBListRecording" />
          </span>
          <span className="hidden-lg">
            <Translation translate="MYTD_MBListRec" />
          </span>
        </th>

        {/* Tags Column */}
        <th className="col-xs-3">
          <Translation translate="MYTD_MBListTags" />
        </th>

        {/* Empty Column for Actions */}
        <th className="col-xs-1"></th>
      </tr>
    </thead>
  );
};

export default TableHeader;
