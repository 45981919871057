import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

import Header from "@common/Home";
import SiteNavigation from "@common/SiteNavigation/Index";
import Footer from "@common/Footer";

import { fetchTranslations, fetchRedirectionData } from "../../../actions";

import { cultureService } from "@services";
import TitleSetter from "../../../helpers/TitleSetter";

const LayoutPage = () => {
  const dispatch = useDispatch();

  const { translations } = useSelector((state) => state.translations);
  const { languageCode } = useSelector((state) => state.languageData);

  const checkAndFetchTranslations = useCallback(() => {
    const data = cultureService.getCultureOptions();
    if (
      languageCode !== data.LanguageCode ||
      Object.keys(translations).length === 0
    ) {
      dispatch(fetchTranslations(data.LanguageCode));
    }
  }, [dispatch, languageCode, translations]);

  const checkAndFetchCommonData = useCallback(() => {
    const data = cultureService.getCultureOptions();
    if (
      languageCode !== data.LanguageCode ||
      Object.keys(translations).length === 0
    ) {
      dispatch(fetchRedirectionData(data.LanguageCode));
    }
  }, [dispatch, languageCode]);

  useEffect(() => {
    checkAndFetchTranslations();
  }, [checkAndFetchTranslations]);

  useEffect(() => {
    checkAndFetchCommonData();
  }, [checkAndFetchCommonData]);

  return (
    <>
      {translations && (
        <>
          <TitleSetter />
          <Header translations={translations} />
          <SiteNavigation translations={translations} />
          <div id="mainBody" className="body-content">
            <Outlet />
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default LayoutPage;
