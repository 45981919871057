import React, { useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import { redirectToLogin } from "@services";
import { AuthContext } from "@common/AuthContext";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (!isAuthenticated) {
      redirectToLogin();
    }
  }, [isAuthenticated]);

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
