import React, { createContext, useEffect, useState, ReactNode } from "react";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { checkAuthStatus, getAntiForgeryToken } from "@services";
import { setAuthentication } from "../../../actions";

interface AuthContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

interface AuthProviderProps {
  children: ReactNode;
  initialAuthStatus: boolean;
}

const scheduleLoginCheck = (
  checkAuthStatus: () => Promise<void>,
): (() => void) | void => {
  const expirationCookie = Cookies.get("login-expiration-utc");
  if (expirationCookie) {
    const expirationTime = JSON.parse(expirationCookie).Expired * 1000;
    const currentTime = Date.now();
    const timeUntilExpiration = expirationTime - currentTime;

    if (timeUntilExpiration > 0) {
      const timeout = setTimeout(() => {
        checkAuthStatus();
        scheduleLoginCheck(checkAuthStatus);
      }, timeUntilExpiration);

      return () => clearTimeout(timeout);
    } else {
      checkAuthStatus();
    }
  }
};

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  initialAuthStatus,
}) => {
  const [isAuthenticated, setIsAuthenticated] =
    useState<boolean>(initialAuthStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await checkAuthStatus();
        setIsAuthenticated(data);
        dispatch(setAuthentication(data));
        if (data) {
          await fetchAntiForgeryToken();
        }
      } catch (error) {
        console.error("Error checking authentication status:", error);
      }
    };

    const fetchAntiForgeryToken = async () => {
      try {
        await getAntiForgeryToken();
      } catch (error) {
        console.error("Error fetching anti-forgery token:", error);
      }
    };

    const cleanup = scheduleLoginCheck(fetchData);
    return () => cleanup && cleanup();
  }, [dispatch]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
