import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Translation from "@common/Translation";
import EmailList from "@common/EmailList";
import { postShareCopy } from "@services";
import toast from "react-hot-toast";

interface ShareModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  item: Record<string, string>;
  translations: Record<string, string>;
}
const ShareModal: React.FC<ShareModalProps> = ({
  showModal,
  setShowModal,
  item,
  translations,
}) => {
  const closeModal = () => setShowModal(false);
  const [email, setEmail] = useState<string>("");
  const [emailList, setEmailList] = useState<string[]>([]);

  const handleShare = async () => {
    try {
      await postShareCopy({
        productName: "ALL",
        pagesetId: item.id,
        usersEmailOrUsername: emailList,
        shareItemType: "book",
      });

      toast.success(translations.Share_request_successfully_sent);
      setEmail("");
      setEmailList([]);
    } catch (error) {
      console.error("Error sharing pageset:", error);
      toast.error(translations.An_error_occurred_while_processing_your_request);
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      className={showModal ? "in" : ""}
      data-testid="share-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <Translation translate="MYTD_shareTxt" textonly={true} />{" "}
          <Translation translate="Book" textonly={true} />{" "}
          <Translation translate="MYTD_copyText" textonly={true} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <div className="copy-form">
            <p>
              <span>
                <Translation translate="MYTD_shareBook" textonly={true} />
              </span>
            </p>
            <EmailList
              translations={translations}
              email={email}
              setEmail={setEmail}
              emailList={emailList}
              setEmailList={setEmailList}
            />
            <button
              disabled={!emailList.length}
              className="btn btn-lg btn-primary"
              onClick={handleShare}
            >
              <span>
                <Translation translate="Share" textonly={true} />
              </span>
            </button>
            &nbsp;
            <button
              className="btn btn-lg btn-default"
              onClick={() => closeModal()}
            >
              <span>
                <Translation translate="Cancel" textonly={true} />
              </span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal;
