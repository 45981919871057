import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { setCommonFailure, setRedirectionData } from "../actions";

interface Redirect {
  type: string;
  displayText: string;
  redirects: {
    type: string;
    displayText: string;
    url: string;
  }[];
}

interface CommonState {
  redirections: Redirect[];
  error?: string;
}

const initialState: CommonState = {
  redirections: [],
};

const commonDataReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setRedirectionData, (state, action: PayloadAction<Redirect[]>) => {
      state.redirections = action.payload;
    })
    .addCase(setCommonFailure, (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    });
});

export default commonDataReducer;
