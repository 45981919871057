import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

const PopupSurvey = ({ surveyUrl }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const surveyDismissed = Cookies.get("surveyDismissed");

    if (!surveyDismissed) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, []);

  const hidePopup = () => {
    Cookies.set("surveyDismissed", "true", { expires: 30 });

    console.log(
      "surveyDismissed cookie value:",
      Cookies.get("surveyDismissed"),
    );

    setIsVisible(false);

    setTimeout(() => {
      Cookies.set("surveyDismissed", "false", { expires: 30 });
      console.log(
        "surveyDismissed reset to false after 30 days:",
        Cookies.get("surveyDismissed"),
      );
    }, 30000);
  };

  return (
    <>
      {isVisible && (
        <div id="nps-survey" className="survey-popup-container">
          <div className="survey-popup">
            <button
              className="survey__close-button"
              onClick={hidePopup}
              aria-label="Close"
            >
              &#x2715; {/* Unicode 'X' */}
            </button>
            <h3 className="survey-popup__main-text">We value your feedback!</h3>
            <p>
              Your opinion matters to us! Please take a moment to share your
              feedback by clicking the link below.
            </p>
            <a
              href={surveyUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="survey-popup__start-button"
            >
              Take the Survey
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupSurvey;
