import React from "react";

import { Availability } from "types";

interface PdfComponentProps {
  mainUrl: string;
  mainUrlText: string;
  description: string;
  resourceType: string;
  availability: Availability;
}

const PdfComponent: React.FC<PdfComponentProps> = ({
  resourceType,
  mainUrl,
  mainUrlText,
  description,
  availability,
}) => {
  return (
    <div className="panel panel-default" data-testid="pdf-component">
      <div className="panel-body">
        <div className="file-type-pdf" title={resourceType}></div>
        <div className="resource">
          <a
            href={mainUrl}
            target="_blank"
            rel="noreferrer"
            data-testid="main-url"
          >
            {mainUrlText}
          </a>
          {availability ? (
            <>
              <br />
              <span data-testid="availability-title">
                {availability.title}{" "}
              </span>
              {availability.languages.map((langItem, langIndex) => (
                <React.Fragment key={langIndex}>
                  <a
                    href={langItem.url}
                    target="_blank"
                    rel="noreferrer"
                    data-testid={`language-link-${langIndex}`}
                  >
                    {langItem.language}
                  </a>
                  {langIndex < availability.languages.length - 1 && ", "}
                </React.Fragment>
              ))}
            </>
          ) : (
            <div className="desc">
              <span>{""}</span>
            </div>
          )}
          <div className="desc" data-testid="description">
            <span>{description}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PdfComponent;
