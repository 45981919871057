import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Translation from "@common/Translation";
import { getMessages, getMessageBankLanguages } from "@services";
import Table from "./Table";
import { Language, Message } from "./types";

interface State {
  languageData: {
    languageCode: string;
  };
}

const MessageBankMessages: React.FC = () => {
  const { languageCode } = useSelector((state: State) => state.languageData);

  const [messages, setMessages] = useState<Message[]>([]);
  const [availableLangs, setAvailableLangs] = useState<Language[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("-created");
  // const [currentPage, setCurrentPage] = useState<number>(1);
  // const [totalPageCount, setTotalPageCount] = useState<number>(1);
  // const [totalMessagesCount, setTotalMessagesCount] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await getMessages(languageCode, {
          sort,
          search: null,
          searchField: "all",
          pagination: { page: 1, pageSize: 25 },
          filter: { verified: null },
        });
        const fetchedMessages = data._Embedded.recordedMessages.map(
          (msg: Message) => ({
            ...msg,
            selected: false,
          }),
        );
        setMessages(fetchedMessages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [languageCode, sort]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getMessageBankLanguages(languageCode);
      setAvailableLangs(data._Embedded.languageModels);
    };
    fetchData();
  }, [languageCode]);

  const handleSortChange = (column: string) => {
    setSort((prevSort) => (prevSort === column ? `-${column}` : column));
  };

  const handleMessageSelect = (message: Message) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === message.id ? { ...msg, selected: !msg.selected } : msg,
      ),
    );
  };

  const handleSelectAll = (selectAll: boolean) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) => ({ ...msg, selected: selectAll })),
    );
  };

  return (
    <div style={{ position: "relative" }}>
      {/* Header buttons */}
      <div style={{ position: "absolute", right: 0, top: -123 }}>
        <button
          className="btn btn-primary btn-lg pull-left"
          style={{ marginRight: "5px" }}
        >
          <Translation translate="MYTD_uploadLbl" />
        </button>
        <button className="btn btn-primary btn-lg pull-left">
          <Translation translate="MYTD_downloadAllLbl" />
        </button>
      </div>

      <div className="container ms-bank">
        <div className="mmb-wrapper">
          <div className="fade-block fade-in margin-top-lg mb-app">
            {/* Loading state */}
            {loading && (
              <div className="alert alert-info">
                <Translation translate="MYTD_MBMsgLoading" />
              </div>
            )}

            {/* Messages table */}
            {messages.length > 0 && (
              <Table
                messages={messages}
                setMessages={setMessages}
                sort={sort}
                handleSortChange={handleSortChange}
                onMessageSelect={handleMessageSelect}
                onSelectAll={handleSelectAll}
                availableLangs={availableLangs}
              />
            )}

            {/* No messages found */}
            {!loading && messages.length === 0 && (
              <div className="alert alert-warning">
                <Translation translate="MYTD_noMsgLbl" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageBankMessages;
