import React, { useState, useEffect, useRef } from "react";
import Translation from "@common/Translation";
import { Language, Message } from "../types";

interface MessageTitleProps {
  message: Message;
  availableLangs: Language[];
  onMessageLabelUpdate: (message: Message, newLabel: string) => void;
}

const MessageTitle: React.FC<MessageTitleProps> = ({
  message,
  availableLangs,
  onMessageLabelUpdate,
}) => {
  const [label, setLabel] = useState(message.label || "");
  const [editMode, setEditMode] = useState(false);
  const titleInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (editMode) titleInputRef.current?.focus();
  }, [editMode]);

  const toggleEditMode = (e: React.MouseEvent) => {
    e.preventDefault();
    setEditMode((prev) => !prev);
  };

  const confirmUpdate = (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const newLabel = (e.target as HTMLInputElement).value
      .trim()
      .replace(/\s{2,}/g, " ");
    setEditMode(false);
    onMessageLabelUpdate(message, newLabel);
  };

  const languageModel = availableLangs.find(
    (lang) => lang.id === message.languageModelId,
  );

  return (
    <span>
      {editMode ? (
        <input
          type="text"
          className="form-control"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && confirmUpdate(e)}
          onBlur={confirmUpdate}
          ref={titleInputRef}
        />
      ) : !message.label ? (
        <button className="btn btn-gray btn-xs" onClick={toggleEditMode}>
          <Translation translate="MYTD_addLbl" />
        </button>
      ) : (
        <a href="#" onClick={toggleEditMode}>
          {message.label}
          {languageModel && (
            <div>
              <Translation translate={languageModel.languageLocalizationKey} />
              {languageModel.localeLocalizationKey && (
                <>
                  {" - "}
                  <Translation
                    translate={languageModel.localeLocalizationKey}
                  />
                </>
              )}
            </div>
          )}
        </a>
      )}
    </span>
  );
};

export default MessageTitle;
