import React from "react";
import Translation from "@common/Translation";
import { BulkAction, Language, Message, Tag } from "../types";
import MessageTitle from "./MessageTitle";
import MessageTranscriptionText from "./MessageTranscriptionText";
import MessageTranscriptionConfidence from "./MessageTranscriptionConfidence";
import AudioPlayer from "./AudioPlayer";
import Tags from "./MessageTags";
import MessageActions from "./MessageActions";

interface TableRowProps {
  message: Message;
  availableLangs: Language[];
  onMessageSelect: (message: Message) => void;
  onMessageLabelUpdate: (message: Message, newLabel: string) => void;
  onMessageTranscriptionTextUpdate: (message: Message, newText: string) => void;
  onMessageDelete: (message: Message) => void;
  bulkAction: BulkAction;
  resetBulkAction: () => void;
  onSaveRetry: (
    e: React.MouseEvent<HTMLAnchorElement>,
    message: Message,
  ) => void;
  onSaveRetryCancel: (
    e: React.MouseEvent<HTMLAnchorElement>,
    message: Message,
  ) => void;
  onConfidenceClick: (message: Message) => void;
  onTagAdd: (message: Message, tagName: string) => void;
  onTagDelete: (message: Message, tag: Tag) => void;
}

const TableRow: React.FC<TableRowProps> = ({
  message,
  availableLangs,
  onMessageSelect,
  onSaveRetry,
  onSaveRetryCancel,
  onConfidenceClick,
  onMessageLabelUpdate,
  onMessageTranscriptionTextUpdate,
  onTagAdd,
  onTagDelete,
  onMessageDelete,
  bulkAction,
  resetBulkAction,
}) => {
  return (
    <tr>
      {/* Title Column */}
      <td className="col-xs-3">
        <input
          type="checkbox"
          checked={message.selected || false}
          onChange={() => onMessageSelect(message)}
        />
        <MessageTitle
          onMessageLabelUpdate={onMessageLabelUpdate}
          message={message}
          availableLangs={availableLangs}
        />
        <br />
        {message.saveFailed && (
          <div className="alert alert-danger">
            <Translation translate="MYTD_saveFailedMsg" />
            <br />
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                onSaveRetry(e, message);
              }}
            >
              <Translation translate="MYTD_retryLbl" />
            </a>{" "}
            |{" "}
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                onSaveRetryCancel(e, message);
              }}
            >
              <Translation translate="MYTD_cancelBtnTxt" />
            </a>
          </div>
        )}
      </td>

      {/* Transcription Text Column */}
      <td className="col-xs-3">
        <MessageTranscriptionText
          onMessageTranscriptionTextUpdate={onMessageTranscriptionTextUpdate}
          message={message}
        />
      </td>

      {/* Confidence Column */}
      <td className="col-xs-1 text-center">
        <MessageTranscriptionConfidence
          message={message}
          onConfidenceClick={onConfidenceClick}
        />
      </td>

      {/* Audio Player Column */}
      <td className="col-xs-1">
        <AudioPlayer
          messageDuration={message.length}
          messageSource={message._Links.audio.href}
          // TODO: Get the access token
          accessToken=""
        />
      </td>

      {/* Tags Column */}
      <td className="col-xs-3">
        <Tags message={message} onTagAdd={onTagAdd} onTagDelete={onTagDelete} />
      </td>

      {/* Delete Action Column */}
      <td className="col-xs-1 text-right">
        <MessageActions
          message={message}
          bulkAction={bulkAction}
          resetBulkAction={resetBulkAction}
          onMessageDelete={onMessageDelete}
        />
      </td>
    </tr>
  );
};

export default TableRow;
