import React, { ReactElement, useState } from "react";
import { emailMessaging, emailRegex } from "../../../helpers/utilities";
import Translation from "@common/Translation";
import { checkUserEmail } from "@services";

interface EmailInputFieldProps {
  translations: Record<string, string>;
  email: string;
  setEmail: (email: string) => void;
  emailList: string[];
  setEmailList: (
    update: string[] | ((prevState: string[]) => string[]),
  ) => void;
  isOwner?: boolean;
  handleAddSync?: (email: string) => void;
}

const EmailInputField: React.FC<EmailInputFieldProps> = ({
  translations,
  email,
  setEmail,
  emailList,
  setEmailList,
  isOwner = false,
  handleAddSync,
}) => {
  const [error, setError] = useState<ReactElement | string | undefined>();

  const addEmail = async (newEmail: string) => {
    // Check if email format is valid
    if (!emailRegex.test(newEmail)) {
      setError(emailMessaging.invalidEmail);
      return;
    }
    // Check if the email is already in the list
    const isAlreadyInList = emailList.some(
      (item) => item.toLowerCase() === email.toLowerCase(),
    );
    if (isAlreadyInList) {
      setError(emailMessaging.emailAlreadyInList);
      return;
    }

    // Check if email exists
    const emailExists = await checkUserEmail(newEmail);
    if (!emailExists.exists) {
      setError(emailMessaging.emailNotFound);
      return;
    }

    if (isOwner && handleAddSync) {
      handleAddSync(email);
    }

    setEmailList((prevEmailList: string[]) => [...prevEmailList, newEmail]);
    setEmail("");
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    email: string,
  ) => {
    if (event.key === "Enter") {
      addEmail(email);
    }
  };
  return (
    <>
      <label htmlFor="ShareEmail" className="control-label">
        <b>
          <span>
            <Translation translate="MYTD_shareTxt7" textonly={true} />
          </span>
        </b>
      </label>
      <div className="input-group inherit-width">
        <input
          type="text"
          id="ShareEmail"
          className="form-control"
          placeholder={translations.Email_Input_Placeholder}
          value={email}
          data-testid="email-input"
          onChange={(e) => {
            setEmail(e.target.value);
            setError("");
          }}
          onKeyDown={(event) => handleKeyPress(event, email)}
        />
        <span className="input-group-btn">
          <button className="btn btn-primary" onClick={() => addEmail(email)}>
            <Translation translate="MYTD_shareTxt8" textonly={true} />
          </button>
        </span>
      </div>
      {error && (
        <div className="share-form-alert" style={{ color: "red" }}>
          {error}
        </div>
      )}
    </>
  );
};

export default EmailInputField;
