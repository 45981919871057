import React from "react";
import { Modal } from "react-bootstrap";
import { BulkAction, Message } from "./types";
import Translation from "@common/Translation";

interface MessageDeleteModalProps {
  showModal: boolean;
  message: Message;
  onConfirmationClose: () => void;
  onMessageDelete: (message: Message) => void;
  bulkAction: BulkAction;
  resetBulkAction: () => void;
}

const MessageDeleteModal: React.FC<MessageDeleteModalProps> = ({
  showModal,
  message,
  onConfirmationClose,
  onMessageDelete,
  bulkAction,
  resetBulkAction,
}) => {
  const handleDelete = () => {
    onMessageDelete(message);
    resetBulkAction();
    onConfirmationClose();
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        onConfirmationClose();
        resetBulkAction();
      }}
      backdrop="static"
      className={showModal ? "in" : ""}
    >
      <Modal.Header>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onConfirmationClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h2>
          <Translation translate="MYTD_deleteMsgLbl" />
        </h2>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Translation translate="MYTD_deleteMsgWarnTxt1" />
          <br />
          <em>
            <Translation translate="MYTD_actionWarnTxt" />
          </em>
        </p>
      </Modal.Body>
      <Modal.Footer>
        {!bulkAction.complete ? (
          <>
            <button className="btn btn-default" onClick={onConfirmationClose}>
              <Translation translate="MYTD_cancelBtnTxt" />
            </button>
            <button
              className="btn btn-primary"
              onClick={handleDelete}
              disabled={bulkAction.inProgress}
            >
              {bulkAction.inProgress ? (
                <>
                  <Translation translate="MYTD_deletingTxt" />
                  <i
                    className="fa fa-circle-o-notch fa-spin"
                    aria-hidden="true"
                  ></i>
                </>
              ) : (
                <Translation translate="MYTD_deleteLbl" />
              )}
            </button>
          </>
        ) : (
          <button className="btn btn-primary" onClick={onConfirmationClose}>
            <Translation translate="MYTD_msgDeletedTxt" />
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default MessageDeleteModal;
