import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

interface AudioPlayerProps {
  messageSource: string;
  messageDuration?: string;
  accessToken: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  messageSource,
  messageDuration,
  accessToken,
}) => {
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );
  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  useEffect(() => {
    const audioPlayer = new Audio(
      `${messageSource}?access_token=${accessToken}`,
    );
    audioPlayerRef.current = audioPlayer;

    const handleEnded = () => setIsPlaying(false);
    audioPlayer.addEventListener("ended", handleEnded);

    return () => {
      audioPlayer.pause();
      audioPlayer.src = "";
      audioPlayer.removeEventListener("ended", handleEnded);
    };
  }, [messageSource, accessToken]);

  const togglePlay = () => {
    const audioPlayer = audioPlayerRef.current;
    if (!audioPlayer) return;

    if (isPlaying) {
      audioPlayer.pause();
    } else {
      audioPlayer.play();
    }

    setIsPlaying(!isPlaying);
  };

  const formattedDuration = messageDuration?.includes("00:0")
    ? messageDuration.split(/00:0(.+)?/)[1]
    : messageDuration;

  return (
    <div className="recording">
      <span
        className={`audio-player-icon ${isPlaying ? "stop" : "play"}`}
        onClick={togglePlay}
        title={
          isPlaying
            ? translations.MYTD_stopMsgLbl
            : translations.MYTD_playMsgLbl
        }
      ></span>
      {formattedDuration && (
        <span className="duration hidden-xs hidden-sm">
          <small>{formattedDuration}</small>
        </span>
      )}
    </div>
  );
};

export default AudioPlayer;
