import React, { useState } from "react";
import { codeMap } from "@helpers/districtVerifyCodes";
import FormErrors from "@common/FormErrors";
import ProfileHeader from "./ProfileHeader";
import ProfileDetails from "./ProfileDetails";
import ProfileAvatar from "./ProfileAvatar";
import ProfileAccountType from "./ProfileAccountType";
import OrgUserComp from "./OrgUserComp";
import OrgUserCompCodeCheck from "./OrgUserCompCodeCheck";
import ProfessionalComp from "./ProfessionalComp";
import ProfileAddress from "./ProfileAddress";
import CityDetailsComp from "./CityDetailsComp";
import UpdateButton from "./UpdateButton";
import ProfileUpdatedComp from "./ProfileUpdatedComp";
import ProfileModal from "./ProfileModal";
const ProfileForm = ({
  updateOk,
  formErrors,
  profile,
  handleFileInputChange,
  clearAvatarClick,
  handleTextUpdate,
  handleDistCodeCheck,
  handleDistCodeDelete,
  toggleModal,
  showModal,
  codeChecking,
  codeValid,
  codeDeleting,
  districtCodeData,
  validateForm,
  handlePageRefresh,
  avatarFileValid,
}) => {
  const [occupationTypeId, setOccupationTypeId] = useState(
    profile.occupationTypeId || "0",
  );

  const [bmoDistrictCode, setBmoDistrictCode] = useState(
    profile.bmoDistrictCode || "",
  );

  const [organization, setOrganization] = useState(profile.organization || "");
  const [occupationId, setOccupationId] = useState(profile.occupationId || "0");
  const [industryId, setIndustryId] = useState(profile.industryId || "0");

  const handleOccupationTypeChange = (e) => {
    setOccupationTypeId(e.target.value);
    handleTextUpdate(e);
  };

  const handleBmoDistrictCodeChange = (e) => {
    setBmoDistrictCode(e.target.value);
    handleTextUpdate(e);
  };

  const handleOrganizationChange = (e) => {
    setOrganization(e.target.value);
    handleTextUpdate(e);
  };

  const handleOccupationIdChange = (e) => {
    setOccupationId(e.target.value);
    handleTextUpdate(e);
  };

  const handleIndustryIdChange = (e) => {
    setIndustryId(e.target.value);
    handleTextUpdate(e);
  };

  return (
    <div id="profileForm">
      {profile && (
        <div className="container">
          <ProfileHeader></ProfileHeader>
          {!updateOk ? (
            <div className="fade-block fade-in">
              <FormErrors formErrors={formErrors} />
              <ProfileDetails profile={profile}></ProfileDetails>
              <hr />
              <ProfileAvatar
                profile={profile}
                clearAvatarClick={clearAvatarClick}
                handleFileInputChange={handleFileInputChange}
                avatarFileValid={avatarFileValid}
              ></ProfileAvatar>
              <hr />
              <ProfileAccountType
                occupationTypeId={occupationTypeId}
                handleOccupationTypeChange={handleOccupationTypeChange}
              ></ProfileAccountType>
              <div className="row">
                {Number(occupationTypeId) === 5 && (
                  <OrgUserComp
                    bmoDistrictCode={bmoDistrictCode}
                    handleBmoDistrictCodeChange={handleBmoDistrictCodeChange}
                    codeChecking={codeChecking}
                    codeValid={codeValid}
                    districtCodeData={districtCodeData}
                    toggleModal={toggleModal}
                    codeDeleting={codeDeleting}
                    handleDistCodeCheck={handleDistCodeCheck}
                  ></OrgUserComp>
                )}
              </div>
              {Number(occupationTypeId) === 5 && (
                <OrgUserCompCodeCheck
                  codeChecking={codeChecking}
                  codeMap={codeMap}
                  districtCodeData={districtCodeData}
                ></OrgUserCompCodeCheck>
              )}
              {Number(occupationTypeId) === 4 && (
                <ProfessionalComp
                  organization={organization}
                  handleOrganizationChange={handleOrganizationChange}
                  occupationId={occupationId}
                  handleOccupationIdChange={handleOccupationIdChange}
                  industryId={industryId}
                  handleIndustryIdChange={handleIndustryIdChange}
                ></ProfessionalComp>
              )}
              <hr />
              <ProfileAddress
                profile={profile}
                handleTextUpdate={handleTextUpdate}
              ></ProfileAddress>
              <CityDetailsComp
                handleTextUpdate={handleTextUpdate}
                profile={profile}
              ></CityDetailsComp>
              <hr />
              <UpdateButton validateForm={validateForm}></UpdateButton>
            </div>
          ) : (
            <ProfileUpdatedComp
              handlePageRefresh={handlePageRefresh}
            ></ProfileUpdatedComp>
          )}
        </div>
      )}
      <ProfileModal
        showModal={showModal}
        toggleModal={toggleModal}
        handleDistCodeDelete={handleDistCodeDelete}
        profile={profile}
      ></ProfileModal>
    </div>
  );
};

export default ProfileForm;
