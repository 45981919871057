import React from "react";
import { Link } from "types";
import DownloadComponent from "./DownloadComponent";

interface TabContentProps {
  links: Link[];
}
const TabContent: React.FC<TabContentProps> = ({ links }) => {
  const categories = Array.from(new Set(links.map((link) => link.category)));
  return (
    <div data-testid="myth-myths" className="container-full">
      <div className="container">
        <div className="resources">
          {categories.map((category) => (
            <div key={category}>
              <h2>{category}</h2>

              <div className="panel-group">
                {links
                  .filter((link) => link.category === category)
                  .map((linkItem, index) => (
                    <DownloadComponent
                      key={index}
                      resourceType={linkItem.resourceType}
                      mainUrl={linkItem.mainUrl}
                      mainUrlText={linkItem.mainUrlText}
                      description={linkItem.description}
                    />
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TabContent;
