import { createAction } from "@reduxjs/toolkit";
import { getRedirectionData } from "@services";
import { AppDispatch } from "../store";

interface Redirect {
  type: string;
  displayText: string;
  redirects: {
    type: string;
    displayText: string;
    url: string;
  }[];
}

export const setRedirectionData = createAction<Redirect[]>(
  "common/setRedirectionData",
);
export const setCommonFailure = createAction<string>("common/setCommonFailure");

export const fetchRedirectionData =
  (langCode: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await getRedirectionData(langCode);
      dispatch(setRedirectionData(response));
    } catch (error) {
      dispatch(
        setCommonFailure(
          error instanceof Error ? error.message : "An unknown error occurred",
        ),
      );
    }
  };
