import React from "react";
import Translation from "@common/Translation";

const MoreStuffProVerifyBtn = ({ onClick }) => {
  return (
    <button
      type="submit"
      onClick={onClick}
      className="btn btn-lg btn-primary margin-top-md"
      data-testid="button"
    >
      <Translation translate="MYTD_verifyLbl" />
    </button>
  );
};

export default MoreStuffProVerifyBtn;
