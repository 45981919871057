import React, { useState } from "react";
import { Message } from "../types";
import PopoverWrapper from "../../Reports/PopoverWrapper";
import Translation from "@common/Translation";

interface MessageTranscriptionConfidenceProps {
  message: Message;
  onConfidenceClick: (message: Message) => void;
}

const MessageTranscriptionConfidence: React.FC<
  MessageTranscriptionConfidenceProps
> = ({ message, onConfidenceClick }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const togglePopover = () => setIsPopoverOpen((prev) => !prev);

  const getTranscriptionConfidenceIcon = (confidence: number | null) => {
    const iconClass = getConfidenceClass(confidence);

    const icon = (
      <span
        className={`confidence-icon ${iconClass}`}
        onClick={togglePopover}
        style={{ cursor: "pointer" }}
      ></span>
    );

    return ["high", "med", "low"].includes(iconClass) ? (
      <PopoverWrapper
        isPopoverOpen={isPopoverOpen}
        togglePopover={togglePopover}
        content={
          <div className="popover-container">
            <button
              className="btn btn-default"
              onClick={() => setIsPopoverOpen(false)}
            >
              <Translation translate="MYTD_cancelBtnTxt" />
            </button>{" "}
            <button
              className="btn btn-primary"
              onClick={() => {
                setIsPopoverOpen(false);
                onConfidenceClick(message);
              }}
            >
              <Translation translate="MYTD_verifyLbl" />
            </button>
          </div>
        }
      >
        {icon}
      </PopoverWrapper>
    ) : (
      icon
    );
  };

  const getConfidenceClass = (confidence: number | null) => {
    if (!message.transcription.text) {
      return message.transcription.state === "Pending" ? "pending" : "missing";
    }
    if (message.transcription.verified) return "verified";
    if (confidence && confidence >= 0.9) return "high";
    if (confidence && confidence >= 0.7) return "med";
    if (confidence && confidence > 0) return "low";
    return "failed";
  };

  return (
    <div>
      {getTranscriptionConfidenceIcon(message.transcription.confidence)}
      {message.transcription.confidence && (
        <span className="hidden-xs hidden-sm">
          <br />
          <small>{Math.round(message.transcription.confidence * 100)}%</small>
        </span>
      )}
    </div>
  );
};

export default MessageTranscriptionConfidence;
